.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-upload {
  display: inline-flex;
  padding: 0.375rem 1.375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background: #D9D9D9;
  box-sizing: border-box,
}
.btn-upload-text {
  color: #000;
text-align: center;
font-family: Pretendard;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.whole-page{
  width: 100%;
  max-width: 430px;
  font-size: 16px;
  box-sizing: border-box,

}
.top-page {
  padding: 1.8125rem 4.625rem 2.3125rem 1rem;
  align-items: center;
  box-sizing: border-box
}
.greeting {
  width: 100%;
}
.greeting-username {
  color: #000;
  font-family: Pretendard;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.greeting-product {
  color: #000;
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.file-page{
  width: 100%;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  flex-shrink: 0;
  box-sizing: border-box
  }

.file-preview{
  display: flex;
  width: 100%;
  padding: 0.375rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 1px solid #000;
  box-sizing: border-box;
  margin-top: 10px;
}

.file-preview-text{
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.file-count {
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }

.preview-image {
  width: 30%;
  margin-bottom: 20px;
  margin-right: 3.3%;
  height: auto;
}

.preview-image-list{
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  flex-wrap: wrap;

}
.file-selection {
  width: 100%;
  box-sizing: border-box
}


  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
  }
  
  
.middle-page {
  display: flex;
  width: 100%;
  padding: 2.1875rem 1rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  align-items: stretch; /* 엘리먼트들이 꽉 차게 정렬 */
  flex-direction: column;

}

.field-text {
  width: 23%;
  height: 1.0625rem;
  flex-shrink: 0;

  color: #000;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
// 브랜드는 지피티가 인식해줘
// .brand {
//   width: 20.9375rem;
//   height: 1.8125rem;
//   flex-shrink: 0;
//   box-sizing: border-box
// }

.field {
  width: 100%;
  height: 1.8125rem;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
  flex-direction: row;
  justify-content:flex-start;
  flex-grow: 1;
  margin-top: 3%; /* 엘리먼트들 간의 위쪽 간격 설정 */
}
.field-input{
display: flex;
width: 40%;
padding: 0.4375rem 0.625rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
background: #D9D9D9;
box-sizing: border-box;
border: none;
}
.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker__input-container{
  width: 100%;
}
.date-field{
  width: 100%;
  height: 1.8125rem;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
  flex-direction: row;
  justify-content:flex-start;
  flex-grow: 1;
  margin-top: 3%; /* 엘리먼트들 간의 위쪽 간격 설정 */

}

.datepicker {
  width: 100%;
  display: flex;
padding: 0.4375rem 0.625rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
background: #D9D9D9;
box-sizing: border-box;
border: none;

}
.field-dropdown{
  display: flex;
  width: 35%;
  padding: 0.375rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  box-sizing: border-box;
  background: #D9D9D9;
  border: none;
  margin-left: auto;

}

.bottom-page {
  display: flex;
  width: 100%;
  padding: 1.375rem 1rem 1.4375rem 1rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box
}

.button-field{
  display: flex;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.button-send{
  display: inline-flex;
padding: 0.375rem 0.625rem;
margin-bottom: 5px;
justify-content: center;
align-items: center;
gap: 0.625rem;
width: 100%;
box-sizing: border-box

}

.button-text{
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button-send-result{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 0.5px solid #000;
  width: 100%;
  box-sizing: border-box

}
.button-send-result-text{
  color: #000;
text-align: center;
font-family: Pretendard;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.result-image-list {
  display: flex;
  justify-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;

}
.result-image-wrap {
  display: flex;
  flex-direction: column;
  margin-right: 2.3%;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  width: 31%;
}

.result-preview-image {
  width: 100%;
  margin-bottom: 1%;
  height: auto;

}

.result-text {

}

.hide {
  display: none;
}


#file {
  display: none;
}



// 모달
.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.modal-open-button, .modal-close-btn {
  cursor: pointer;
  margin-left: auto;
}

.modal-container {
  
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  width: 250px;
  height: 150px;
  padding: 15px;
}

